import { UseFormReturn, FieldValues, Path } from 'react-hook-form'

import Toastr from '~/services/Toastr'

export type ErrorHandler = {
  _base?: string
  errors?:
    | Record<string, string>
    | {
        _base?: string
        base?: string
        shipment?: string
        email?: string
      }
}

const useFormErrorHandler = <TFormValues extends FieldValues>(
  setError?: UseFormReturn<TFormValues>['setError'],
  hideToastr = false
) => {
  const errorHandler = (data: ErrorHandler) => {
    const baseError =
      data?._base ||
      data?.errors?._base ||
      data?.errors?.base ||
      data?.errors?.shipment ||
      data?.errors?.email

    const showToastr = () => {
      if (!hideToastr) {
        Toastr.error(baseError || 'Something went wrong')
      }
    }

    if (setError) {
      if (data?.errors && !baseError) {
        Object.entries(data.errors).forEach(
          ([key, message]: [string, string]) => {
            setError(key as Path<TFormValues>, { message })
          }
        )
      } else {
        showToastr()
      }
    } else {
      showToastr()
    }
  }

  return errorHandler
}

export default useFormErrorHandler
