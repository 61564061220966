import { useRouter } from 'next/router'

import { useForm } from 'react-hook-form'

import useFormErrorHandler from '~/hooks/useFormErrorHandler'
import { DATE_FORMAT } from '~/utils/constants/dayjs.constants'

import SearchFormTemplate from './templates/SearchFormTemplate'

const SearchForm = () => {
  const router = useRouter()
  const methods = useForm<SearchFV>()

  const handleFormError = useFormErrorHandler(methods.setError)

  const { isSubmitting } = methods.formState

  const onSubmit = async ({
    location,
    bake_category,
    event_date
  }: SearchFV) => {
    try {
      router.push({
        pathname: '/search',
        query: {
          ...(location && {
            center: `${location.location.lat}, ${location.location.lng}`,
            location: location.label
          }),
          ...(bake_category && { bake_category }),
          ...(event_date && { event_date: event_date.format(DATE_FORMAT) })
        }
      })
    } catch (error: any) {
      handleFormError(error?.data)
    }
  }

  const onSubmitting = methods.handleSubmit(onSubmit)

  return (
    <SearchFormTemplate
      methods={methods}
      onSubmit={onSubmitting}
      isSubmitting={isSubmitting}
    />
  )
}

export default SearchForm
