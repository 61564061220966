import React, { FC } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Grid, IconButton, InputAdornment } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import DatePickerField from '~/components/fields/DatePickerField'
import GeosuggestField from '~/components/fields/GeosuggestField/GeosuggestField'
import SelectField from '~/components/fields/SelectField'
import StaticDatePickerTemplate from '~/components/fields/StaticDatePickerField/StaticDatePickerTemplate'
import LocalizationProvider from '~/containers/LocalizationProvider'

import Api from '~/services/Api'

import TypeOfBakesIcon from '~/assets/icons/filters/cake.svg'
import LocationIcon from '~/assets/icons/home/location.svg'
import SearchIcon from '~/assets/icons/search.svg'

import msg from './SearchFormTemplate.messages'

interface SearchFormTemplateProps {
  methods: any
  onSubmit?: () => void
  isSubmitting: boolean
}

const SearchFormTemplate: FC<SearchFormTemplateProps> = ({
  isSubmitting,
  methods,
  onSubmit
}) => {
  const { formatMessage } = useIntl()

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        maxWidth={930}
        mx="auto"
        onSubmit={onSubmit}
      >
        <Grid
          container
          spacing={{ xs: 1.7, lg: 0.5 }}
          columns={12}
          alignItems="center"
        >
          <Grid item xs={12} lg={3.3}>
            <GeosuggestField
              name="location"
              placeholder={formatMessage(msg.labelLocation)}
              size="medium"
              country="AU"
              types={['locality', 'postal_code']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="end">
                      <LocationIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} lg={3.3}>
            <SelectField
              name="bake_category"
              placeholder={formatMessage(msg.labelBakeType)}
              optionSettings={{
                loadOptions: async () => {
                  const resp = await Api.getBakeCategories()
                  return resp
                },
                optionValue: 'name'
              }}
              SelectProps={{
                displayEmpty: true,
                multiple: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TypeOfBakesIcon />
                  </InputAdornment>
                )
              }}
              autoComplete="bake_category"
              size="medium"
            />
          </Grid>

          <Grid item xs={12} lg={3.3}>
            <LocalizationProvider>
              <StaticDatePickerTemplate>
                <DatePickerField
                  name="event_date"
                  placeholder={formatMessage(msg.labelEventDate)}
                />
              </StaticDatePickerTemplate>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} lg={2}>
            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={isSubmitting}
              startIcon={<SearchIcon />}
              sx={{ fontWeight: 600, py: 1.7 }}
            >
              <FormattedMessage {...msg.searchBtn} />
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  )
}

export default SearchFormTemplate
