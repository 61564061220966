import { defineMessages } from 'react-intl'

const msg = defineMessages({
  labelLocation: {
    defaultMessage: 'Location'
  },
  labelBakeType: {
    defaultMessage: 'Bake type'
  },
  labelEventDate: {
    defaultMessage: 'Event date'
  },
  searchBtn: {
    defaultMessage: 'Search'
  }
})

export default msg
